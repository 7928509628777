import { downloadTemplate, requiredHeaders, scholarStatus, getHeaders, configureRoaster,getTableDetails, getAutoSaveTableDetails,
  getAutoSaveStep2, deleteRoasterAPI } from "@/services/scholar/scholarService";
import store from "@/store";
import {
    getModule,
    Module,
    VuexModule,
    Mutation,
    Action
  } from "vuex-module-decorators";

  @Module({
    namespaced: true,
    name: "scholar",
    store,
    dynamic: true
  })
  class ScholarModule extends VuexModule {
    public downloadRes: any = {};
    public requiredHeaderRes: any = {};
    public scholarRoasterRes: any = {};
    public headerResponse: any = {};
    public configureRoasterRes: any = {};
    public tableGetResponse:any = {};
    public getAutoSaveStep2: any = {};
    public deletedRoasterRes: any = {};

    

    get tableData(){
      return this.tableGetResponse;
    }

    get downloadResponse(){
        return this.downloadRes;
    }

    get bxMustHeaders(){
      return this.requiredHeaderRes;
  }

  get ScholarStatus(){
    return this.scholarRoasterRes;
  }

  get bxCsvHeaders(){
    return this.headerResponse;
  }

  get ConfigRoast(){
    return this.configureRoasterRes;
  }

  get autoSaveForStep2(){
    return this.getAutoSaveStep2;
  }

    @Mutation
    public setDownload(payload:any) {
      this.downloadRes = payload
    }
  

    @Action({commit: "setDownload"})
    public download():Promise<Object>{
      let resultNew = async function downloadTemplates(): Promise<Object> {
        try {
          let promise = downloadTemplate();
        let result = await promise;  
        return result as object;
        } catch {
          return {status: 500}
        }
      }
      return resultNew();
    }

    @Mutation
    public setrequiredHeader(payload:any) {
      this.requiredHeaderRes = payload
    }
  

    @Action({commit: "setrequiredHeader"})
    public bxrequiredHeaders():Promise<Object>{
      let resultNew = async function downloadTemplates(): Promise<Object> {
        try {
          let promise = requiredHeaders();
          let result = await promise;  
          return result as object;
        }  catch {
          return {status: 500}
        }
      }
      return resultNew();
    }


    @Mutation
    public getScholarStatus(payload:any) {
      this.requiredHeaderRes = payload
    }
  

    @Action({commit: "getScholarStatus"})
    public checkScholarStatus(sideId: number):Promise<Object>{
      let resultNew = async function extractScholarStatus(Id: number): Promise<Object> {
        try {
          let promise = scholarStatus(Id);
        let result = await promise;  
        return result as object;
        } catch {
          return {status: 500}
        }
      }
      return resultNew(sideId);
    }

    @Mutation
    public setBxCsvHeaders(payload:any) {
      this.headerResponse = payload
    }
  

    @Action({commit: "setBxCsvHeaders"})
    public getAllHeaders(sideId: number):Promise<Object>{
      let resultNew = async function Headers(Id: number): Promise<Object> {
        try {
          let promise = getHeaders(Id);
        let result = await promise;  
        return result as object;
        } catch {
          return {status: 500}
        }
      }
      return resultNew(sideId);
    }

    @Mutation
    public mutateRoasterState(payload:any) {
      this.configureRoasterRes = payload
    }
  
    
    @Mutation
    public setTableData(payload:any) {
      this.tableGetResponse = payload
    }
  

    @Action({commit: "setTableData"})
    public fetchTableDetails(stepObj:any):Promise<Object>{
      let resultNew = async function Table(stepObj:any): Promise<Object> {
        try {
          let promise = getTableDetails(stepObj);
        let result = await promise;  
        return result as object;
        } catch {
          return {status: 500} as any;
        }
      }
      return resultNew(stepObj);
    }

    
    @Action({commit: "setTableData"})
    public fetchAutoSaveTable(stepObj:any):Promise<Object>{
      let resultNew = async function Table(stepObj:any): Promise<Object> {
        try {
        let promise = getAutoSaveTableDetails(stepObj);
        let result = await promise;  
        return result as object;
        } catch {
          return {status: 500} as any;
        }
      }
      return resultNew(stepObj);
    }

    @Action({commit: "mutateRoasterState"})
    public configureScholarRoaster(payload: {}):Promise<Object>{
    let resultNew = async function configure(obj: {}): Promise<Object> {
      try {
        let promise = configureRoaster(obj);
        let result = await promise;  
        return result as object;
      } catch {
        return {status: 500} as any;
      }
    }
    return resultNew(payload);
    }

    @Mutation
    public autoSaveRes(payload:any) {
      this.getAutoSaveStep2 = payload
    }
  

    @Action({commit: "setTableData"})
    public getStep2AutoSave(payload: any):Promise<Object>{
      let resultNew = async function getAutoSave(payload2: any): Promise<Object> {
        try {
          let promise = getAutoSaveStep2(payload2);
        let result = await promise;  
        return result as object;
        } catch {
          return {status: 500} as any
        }
      }
      return resultNew(payload);
    }

    @Mutation
    public mutateDeletedRoaster(payload:any) {
      this.deletedRoasterRes = payload
    }
   
    @Action({commit:"mutateDeletedRoaster"})
    public async deleteRoaster(siteId:number):Promise<Object>{
      try {
        let promise = deleteRoasterAPI(siteId);
        let result = await promise;  
        return result as object;
      } catch {
        return {status: 500} as any;
      }
        
    }

}
export default getModule(ScholarModule);

import {  getStaffHeaders, requiredStaffHeaders, getStaffAutoSaveData, autoSaveStaffRoster } from "@/services/staff/staffService";
import store from "@/store";
  import {
      getModule,
      Module,
      VuexModule,
      Mutation,
      Action
    } from "vuex-module-decorators";
    import APP_UTILITIES from '@/utilities/commonFunctions'
  
    @Module({
      namespaced: true,
      name: "scholar",
      store,
      dynamic: true
    })
    class StaffModule extends VuexModule {
        public requiredStaffHeaderRes: any = {};
        public staffHeaderResponse: any = {};
        public configureStaffRosterRes:any = {};
        public tableGetResponse:any = {};
    
        get bxMustStaffHeaders(){
            return this.requiredStaffHeaderRes;
        }
    
        get bxCsvStaffHeaders(){
            return this.staffHeaderResponse;
        }
        get ConfigStaffRoster(){
            return this.configureStaffRosterRes;
        }

        @Mutation
        public setBxCsvStaffHeaders(payload:any) {
            this.staffHeaderResponse = payload
        }
    

        @Action({commit: "setBxCsvStaffHeaders"})
        public getAllStaffHeaders(accountId:number):Promise<Object>{
            let {siteId} = APP_UTILITIES.coreids();
            let resultNew = async function Headers(pId:number, sId: number): Promise<Object> {
                let promise = getStaffHeaders(pId);
                let result = await promise;  
                return result as object;
            }
            return resultNew(accountId, siteId);
        }

        @Mutation
        public setrequiredStaffHeader(payload:any) {
            this.requiredStaffHeaderRes = payload
        }
    

        @Action({commit: "setrequiredStaffHeader"})
        public bxrequiredStaffHeaders():Promise<Object>{
            let resultNew = async function downloadTemplates(): Promise<Object> {
                let promise = requiredStaffHeaders();
                let result = await promise;  
                return result as object;
            }
            return resultNew();
        }
        @Mutation
        public mutateStaffState(payload:any) {
            this.configureStaffRosterRes = payload
        }

        @Action({commit: "mutateStaffState"})
        public autoSaveStaffRoster(payload: {}):Promise<Object>{
            let resultNew = async function configure(obj: {}): Promise<Object> {
                let promise = autoSaveStaffRoster(obj);
                let result = await promise;  
                return result as object;
            }
            return resultNew(payload);
        }      

        @Mutation
        public setTableData(payload:any) {
            this.tableGetResponse = payload
        }

        @Action({commit: "setTableData"})
        public getStaffAutoSaveData(payload: any):Promise<Object>{
            let resultNew = async function getAutoSave(payload2: any): Promise<Object> {
                let promise = getStaffAutoSaveData(payload2);
                let result = await promise;  
                return result as object;
            }
            return resultNew(payload);
        }
  
  }
  export default getModule(StaffModule);
  
import { httpIdentityClient, httpRosterClient } from "../common/httpClient";
import API_CONST from '@/constants/ApiConst';
import APP_UTILITIES from "@/utilities/commonFunctions";

export function deleteRosterAPI(accountId:any) {
  let atId = APP_UTILITIES.getCookie('accountId');
    return httpIdentityClient().delete(`${API_CONST.UPLOAD_CSV_STAFF}?accountId=${atId}`).then((response) => {
        return { data: response.data, status: response.status, statusText: response.statusText };
    }).catch((error) => {
        return { data: error.data, status: error.status, statusText: error.statusText };
    });
}

export function staffStatus(accountId:any) {
    return httpIdentityClient().get(`${API_CONST.UPLOAD_CSV_STAFF}?accountId=${accountId}`).then((response) => {
      return { data: response.data, status: response.status, statusText: response.statusText };
    }).catch((error) => {
      return { data: error.data, status: error.status, statusText: error.statusText };
    });
  }

  export function getStaffHeaders(accountId:any) {
    return httpIdentityClient().get(`${API_CONST.STAFF_HEADERS}?accountId=${accountId}`).then((response) => {
      return { data: response.data, status: response.status, statusText: response.statusText };
    }).catch((error) => {
      return { data: error.data, status: error.status, statusText: error.statusText };
    });
  }
  
  export function requiredStaffHeaders() {
    return httpRosterClient().get(`${API_CONST.STAFF_REQUIRED_HEADER}`).then((response) => {
      return { data: response.data, status: response.status, statusText: response.statusText };
    }).catch((error) => {
      return { data: error.data, status: error.status, statusText: error.statusText };
    });
  }
  
  export function getStaffAutoSaveData(payload: any) {
    return httpIdentityClient().get(`${API_CONST.GET_STAFF_AUTOSAVE_ROASTER}?siteId=${payload.siteId}&programId=${payload.programId}&step=${payload.stepNumber}`).then((response) => {
      return { data: response.data, status: response.status, statusText: response.statusText };
    }).catch((error) => {
      return { data: error.data, status: error.status, statusText: error.statusText };
    });
  }
  
  export function autoSaveStaffRoster(payload: any) {
    let accountId = APP_UTILITIES.getCookie('accountId');
    payload.accountId = accountId
    return httpIdentityClient().put(`${API_CONST.CONFIGURE_STAFF_ROASTER}/${payload.rosterStep}`, payload).then((response) => {
      return { data: response.data, status: response.status, statusText: response.statusText };
    }).catch((error) => {
      return { data: error.data, status: error.status, statusText: error.statusText };
    });
  }

  export function rosterStep3Autosave(payload: any) {
    let  accountId = APP_UTILITIES.getCookie('accountId');
    return httpIdentityClient().get(`${API_CONST.ROSTER_STEP3}?accountId=${accountId}&programId=${payload.programId}&id=${payload.id}&active=${payload.active}&search=${payload.search}&page=${payload.page}&count=${payload.count}&sortField=${payload.sortField}&sortDir=${payload.sortDir}&roleType=0`).then((response) => {
      return { data: response.data, status: response.status, statusText: response.statusText };
    }).catch((error) => {
      return { data: error.data, status: error.status, statusText: error.statusText };
    });
  }

  export function updateRosterStep3(payload: any) {
    let  accountId = APP_UTILITIES.getCookie('accountId');
    return httpIdentityClient().put(`${API_CONST.UPDATE_ROSTER_STEP3}?accountId=${accountId}&isError=${payload.isError}`, payload.step3).then((response) => {
      return { data: response.data, status: response.status, statusText: response.statusText };
    }).catch((error) => {
      return { data: error.data, status: error.status, statusText: error.statusText };
    });
  }

  export function commitStaff(payload: {accountId: number}) {
    let accId = APP_UTILITIES.getCookie('accountId');
    return httpIdentityClient().post(`${API_CONST.STAFF_COMMIT}?accountId=${accId}`).then((response) => {
      return { data: response.data, status: response.status, statusText: response.statusText };
    }).catch((error) => {
      return { data: error.data, status: error.status, statusText: error.statusText };
    });
  }
  